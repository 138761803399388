// Components.
import Layout from 'components/Layout/Layout'

// Constants.
import { revalidate } from 'lib/constants/revalidate'

import apiService from 'lib/services/pixels-headless/common'

/**
 * Get common data for 404 page.
 */
export async function getStaticProps({ locale = false }) {
  const data = await apiService.getGlobals(locale)

  return {
    props: {
      data,
    },
    revalidate,
  }
}

export default function ErrorPage404({ data }) {
  return (
    <Layout data={data}>
      <div className="container">
        <h1 className="h2 gradient-text">Sivua ei löytynyt</h1>
        <p>Oho, sivua ei löytynyt!</p>
      </div>
    </Layout>
  )
}
